import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

const LegalNotice = props => {

    const lang = props.pageContext.langKey;

    return (
        <Layout lang={lang} location={props.location}>
            <Seo title='Legal notice | Valudio' meta='Our legal notice' slug='legal-notice' />
            <Helmet>
                <meta property="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="legal">
                <div className="container">
                    <h1 className="legal__heading">
                        Legal notice
                    </h1>
                    <div className="legal__section">
                        <h2 className="legal__subheading">
                            Purpose
                        </h2>
                        <p>
                            This legal notice regulates the use of the website www.valudio.com, owned by Valudio GmbH (from now on, THE OWNER OF THE WEBSITE).
                        </p>
                        <p>
                            Navigation through the website of the OWNER OF THE WEBSITE gives you the status of USER of the same and implies full and unreserved acceptance of each and every one of the conditions published in this legal notice, warning that these conditions may be changed without prior notice from the OWNER OF THE WEBSITE, in which case it will be published and notified as far in advance as possible.
                        </p>
                        <p>
                            The provision of the service of the Page and the contents has, in principle, an indefinite duration. However, THE OWNER OF THE WEBSITE is authorized to terminate or suspend the service of the Page and / or any of the contents at any time, warning of this when reasonably possible.
                        </p>
                        <p>
                            The User also undertakes to make proper use of the website in accordance with the law, good faith, public order, uses of traffic and this Legal Notice, and will be liable to the OWNER OF THE WEBSITE or to third parties for any damages that may be caused as a result of a breach of this obligation.
                        </p>
                        <p>
                            Any use other than that authorized is expressly prohibited, and the OWNER OF THE WEBSITE may refuse or withdraw access and use at any time.
                        </p>
                        <p>
                            It is advisable to read carefully its content in case you wish to access and make use of the information and services offered from this website. If the User does not agree with the conditions of access and use of this Legal Notice, he will refrain from using the Page.
                        </p>
                    </div>
                    <div className="legal__section">
                        <h2 className="legal__subheading">
                            Definitios
                        </h2>
                        <p>
                            – “Page”: domain www.valudio.com which is made available to Internet Users.
                        </p>
                        <p>
                            – “User”: a natural or legal person who uses or browses the Site.
                        </p>
                        <p>
                            – “Content”: these are the pages that make up the entire domain www.valudio.com, which make up the information and services that Valudio GmbH makes available to Internet Users. They contain the messages, texts, photographs, graphics, icons, logos, technology, links, textures, drawings, sound and/or image files, recordings, software, appearance, graphic design and source codes and, in general, any kind of material contained on the Website.
                        </p>
                        <p>
                            – “Web”: technical word that describes the system of access to information via Internet, which is configured by means of pages made with HTML or similar language, and programming mechanisms such as java, javascript, PHP, or others, etc. In these pages designed and published under an Internet domain name are the result of information that the owner makes available to Internet users.
                        </p>
                        <p>
                            – “Hyperlink”: technique by which a User can navigate through different pages of the Web, or the Internet, with a simple click on the text, icon, button or callsign that contains the link.
                        </p>
                    </div>
                    <div className="legal__section">
                        <h2 className="legal__subheading">
                            Identification
                        </h2>
                        <p>
                            THE OWNER OF THE WEBSITE, in compliance with Law 34/2002, of 11 July, on Information Society Services and Electronic Commerce, informs you that
                        </p>
                        <ul>
                            <li>Its company name is: Valudio GmbH</li>
                            <li>Its UID/VAT number is: CHE-440.167.772</li>
                            <li>Its registered office is at: Hardturmstrasse 161, 8005 Zürich</li>
                        </ul>
                    </div>
                    <div className="legal__section">
                        <h2 className="legal__subheading">
                            Communications
                        </h2>
                        <p>
                            To communicate with us, we put at your disposal different means of contact that we detail below:
                        </p>
                        <ul>
                            <li>
                                Tel: +34 654 125 279
                            </li>
                            <li>
                                Email: info@valudio.com
                            </li>
                        </ul>
                        <p>
                            All notifications and communications between users and THE OWNER OF THE WEBSITE will be considered effective, for all purposes, when they are made through any of the means detailed above.
                        </p>
                    </div>
                    <div className="legal__section">
                        <h2 className="legal__subheading">
                            Conditions of Access and Use
                        </h2>
                        <p>
                            The website and its services are free of charge. However, THE OWNER OF THE WEBSITE may condition the use of some of the services offered on its website to the prior completion of the corresponding form.
                        </p>
                        <p>
                            The user guarantees the authenticity and timeliness of all data that communicates to the OWNER OF THE WEBSITE and will be solely responsible for any false or inaccurate statements made.
                        </p>
                        <p>
                            The user expressly agrees to make appropriate use of the contents and services of the OWNER OF THE WEBSITE and not to use them for, among others:
                        </p>
                        <ol>
                            <li>
                                Disseminate criminal, violent, pornographic, racist, xenophobic, offensive, or advocacy of terrorism content or, in general, content that is contrary to the law or public order.
                            </li>
                            <li>
                                Introduce into the network computer viruses or perform actions likely to alter, damage, interrupt or generate errors or damage to electronic documents, data or physical and logical systems of the OWNER OF THE WEBSITE or third parties, as well as hinder the access of other users to the website and its services through the massive consumption of computer resources through which the OWNER OF THE WEBSITE provides its services.
                            </li>
                            <li>
                                Attempt to access the email accounts of other users or restricted areas of the computer systems of the OWNER OF THE WEBSITE or third parties and, where appropriate, extract information.
                            </li>
                            <li>
                                To violate the rights of intellectual or industrial property, as well as to violate the confidentiality of the information of the OWNER OF THE WEB or of third parties.
                            </li>
                            <li>
                                Impersonate any other user.
                            </li>
                            <li>
                                Reproduce, copy, distribute, make available or any other form of public communication, transform or modify the contents, unless you have the authorization of the owner of the corresponding rights or it is legally permitted.
                            </li>
                            <li>
                                Collect data for advertising purposes and to send advertising of any kind and communications for sales purposes or others of a commercial nature without their prior request or consent.
                            </li>
                        </ol>
                        <p>
                            THE OWNER OF THE WEBSITE is not responsible for the use that each user gives to the materials made available on this website or the actions taken on the basis of them.
                        </p>
                        <p>
                            a. INTELLECTUAL AND INDUSTRIAL PROPERTY
                        </p>
                        <p>
                            All the contents of the website, such as texts, photographs, graphics, images, icons, technology, software, as well as its graphic design and source codes, constitute a work whose property belongs to the OWNER OF THE WEBSITE, all rights being reserved, without any of the exploitation rights over them being understood to have been transferred to the User beyond what is strictly necessary for the correct use of the website.
                        </p>
                        <p>
                            The User agrees to respect the rights of intellectual and industrial property owned by the OWNER OF THE WEBSITE. You can view the contents and make, if necessary, authorized private copies provided that the elements reproduced are not subsequently transferred to third parties, or installed on servers connected to networks, or are the subject of any kind of exploitation. The USER must refrain from removing, altering, evading or manipulating any protection device or security system that was installed on the pages of the OWNER OF THE WEBSITE.
                        </p>
                        <p>
                            Also, all trademarks, trade names or logos of any kind that appear on the website are owned by the OWNER OF THE WEBSITE, without the use or access to it can be understood to give the user any right over them
                        </p>
                        <p>
                            The distribution, modification, transfer or public communication of the contents and any other act that has not been expressly authorized by the owner of the rights of exploitation are prohibited. Any use not previously authorized by the OWNER OF THE WEBSITE will be considered a serious breach of the rights of intellectual or industrial property of the author, which will give rise to the legally established responsibilities.
                        </p>
                        <p>
                            b. HYPERLINKS
                        </p>
                        <p>
                            As a service to our visitors, our website may include hyperlinks to other sites that are not operated or controlled by THE OWNER OF THE WEBSITE, and THE OWNER OF THE WEBSITE does not guarantee, nor is it responsible for, the legality, reliability, usefulness, truthfulness and timeliness of the contents of such websites or their privacy practices. Please, before providing your personal information to these websites outside www.valudio.com, be aware that their privacy practices may differ from ours.
                        </p>
                        <p>
                            The establishment of a hyperlink does not imply in any case the existence of relations between the OWNER OF THE WEBSITE and the owner of the website in which it is established, nor the acceptance and approval by the OWNER OF THE WEBSITE of its contents or services.
                        </p>
                        <p>
                            Also, those people who intend to establish hyperlinks between your website and ours, must observe and meet the following conditions:
                        </p>
                        <ul>
                            <li>
                            No prior authorization is required when the Hyperlink only allows access to the home page, but you may not reproduce it in any way. Any other form of Hyperlink will require the express and unequivocal written authorisation of the OWNER OF THE WEBSITE.
                            </li>
                            <li>
                            No “frames” will be created with the web pages or on the web pages of the OWNER OF THE WEBSITE.
                            </li>
                            <li>
                            No false, inaccurate, or offensive statements or indications will be made about THE OWNER OF THE WEBSITE, its directors, its employees or collaborators, or the people who are related to the Site for any reason, or the Users of the Site, or the Content provided.
                            </li>
                            <li>
                            It shall not be stated or given to understand that THE OWNER OF THE WEBSITE has authorized the Hyperlink or has supervised or assumed in any way the contents offered or made available to the website where the Hyperlink is established.
                            </li>
                            <li>
                            The web page in which the Hyperlink is established may only contain what is strictly necessary to identify the destination of the Hyperlink.
                            </li>
                            <li>
                            The Web page in which the Hyperlink is established shall not contain any information or content that is illicit, contrary to morality and generally accepted good customs and public order, nor shall it contain content that is contrary to any rights of third parties.
                            </li>
                        </ul>
                        <p>
                        c. EXCLUSION OF GUARANTEES AND LIABILITY FOR ACCESS AND USE
                        </p>
                        <p>
                        The content of this website is of a general nature and is for information purposes only, and access to all content is not fully guaranteed, nor is its completeness, correctness, validity or timeliness, or its suitability or usefulness for a specific purpose. In no case do they offer or have the character of a binding or contractual commitment.
                        </p>
                        <p>
                        THE OWNER OF THE WEBSITE excludes all liability for decisions that the User may take based on this information, as well as for any possible typographical errors that may be contained in the documents and graphics of the Page. The information is subject to possible periodic changes without prior notice of its content by extension, improvement, correction or updating of the contents.
                        </p>
                        <p>
                        THE OWNER OF THE WEBSITE excludes, to the extent permitted by law, any liability for damages of any kind arising from:
                        </p>
                        <ol>
                            <li>
                            a) The impossibility of access to the website, interruptions or errors in access to the page or the lack of veracity, accuracy, completeness and / or timeliness of the contents, as well as the existence of defects of all kinds of content transmitted, disseminated, stored, made available, which has been accessed through the website or services offered.
                            </li>
                            <li>
                            b) The presence of viruses or other elements in the contents that may produce alterations in the computer systems, electronic documents or user data. The access to the Page does not imply the obligation on the part of the OWNER OF THE WEBSITE to control the absence of virus, worms or any other harmful computer element. It corresponds to the User, in any case, the availability of appropriate tools for the detection and disinfection of harmful computer programs, so THE OWNER OF THE WEBSITE is not responsible for any possible security errors that may occur during the provision of service of the Page, nor for any damage that may be caused to the user’s computer system or third parties, as a result of the presence of viruses in the user’s computer used to connect to services and content of the Web, a malfunction of the browser or the use of outdated versions of it.
                            </li>
                            <li>
                            c) Non-compliance with the law, good faith, public order, the uses of traffic and this legal notice as a consequence of the incorrect use of the website. In particular, and by way of example, THE OWNER OF THE WEBSITE is not responsible for the actions of third parties who violate intellectual and industrial property rights, business secrets, rights to honor, personal and family privacy and image itself, as well as the rules on unfair competition and illegal advertising.
                            </li>
                        </ol>
                        <p>
                        Also, THE OWNER OF THE WEBSITE declines any responsibility for the information that is outside this website and is not managed directly by our webmaster. The function of the links that appear on this website is exclusively to inform the user about the existence of other sources capable of expanding the contents offered by this website. THE OWNER OF THE WEBSITE does not guarantee or take responsibility for the operation or accessibility of the linked sites; nor does it suggest, invite or recommend a visit to them, and therefore it will not be responsible for the results obtained. THE OWNER OF THE WEBSITE is not responsible for the establishment of hyperlinks by third parties.
                        </p>
                        <p>
                        THE OWNER OF THE WEBSITE reserves the right to remove all those comments and contributions that violate respect for the dignity of the person, which are discriminatory, xenophobic, racist, pornographic, that threaten youth or children, order or public safety or that, in his opinion, are not suitable for publication.
                        </p>
                        <p>
                        In any case, THE OWNER OF THE WEBSITE will not be responsible for the opinions expressed by users through blogs or other participation tools that may be created, as provided in the applicable regulations.
                        </p>
                        <p>
                        d. PROCEDURE IN CASE OF ACTIVITIES OF AN ILLICIT NATURE
                        </p>
                        <p>
                        In the event that any user or third party considers that there are facts or circumstances that reveal the unlawful nature of the use of any content and / or the conduct of any activity in the web pages included or accessible through the website, you must send a notification to the OWNER OF THE WEBSITE duly identifying and specifying the alleged violations.
                        </p>
                        <p>
                        e. PUBLICATIONS
                        </p>
                        <p>
                        The administrative information provided through the website does not replace the legal publicity of the laws, regulations, plans, general provisions and acts that have to be formally published in the official journals of public administrations, which are the only instrument that attests to their authenticity and content. The information available on this website should be understood as a guide with no legal validity purpose.
                        </p>
                        <p>
                        f. PRIVACY POLICY. PROTECTION OF PERSONAL DATA
                        </p>
                        <p>
                        Valudio GmbH is aware of the importance of data protection, as well as the privacy of the user. For this reason it has implemented a data processing policy aimed at providing maximum security in the use and collection of data, guaranteeing compliance with current regulations on the subject (RGPD and LOPDGDD) and configuring this policy as one of the basic pillars in the lines of action of the company.
                        </p>
                    </div>
                    <div className="legal__section">
                        <h2 className="legal__subheading">
                            Applicable Laws
                        </h2>
                        <p>
                        The present conditions will be governed by the Spanish legislation in force.
                        </p>
                        <p>
                        The languages used will be Spanish and German
                        </p>
                        <p>
                        All rights are reserved by the laws and international treaties on intellectual property. It is expressly forbidden to copy, reproduce or disseminate them, in whole or in part, by any means.
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default LegalNotice;